/**
 * @typedef {Object} OriginConfigurationObject
 * @property {string} clientID The client ID for the PKCE flow
 * @property {string} issuer The OIDC issuer
 * @property {string} environment The environment of the marketing site
 * @property {postLogoutRedirectUri} clientID The URL the user will be directed to post logout
 */

import { postLogoutConfiguration } from './postLogoutConfiguration';

const originHashMap = new Map([
    [
        ['https://wwq.transaction.card.fnbo.com', 'http://localhost:8080'],
        {
            clientId: '0oa1rkiz2plyKPdmQ0h8',
            issuer: 'https://authq.securebanklogin.com/oauth2/default',
            environment: 'wwq',
        },
    ],
    [
        [
            'https://www.transaction.card.fnbo.com',
            'https://transaction-site-root-config.prod.platform.fnbo.internal',
        ],
        {
            clientId: '0oads0z1zpC9iN9RT4x7',
            issuer: 'https://auth.securebanklogin.com/oauth2/default',
            environment: 'www',
        },
    ],
    [
        [
            'https://wwd.transaction.card.fnbo.com',
            'https://wwt.transaction.card.fnbo.com',
            'https://transaction-site-root-config.dev.platform.fnbo.internal',
        ],
        {
            clientId: '0oadj6lytrp6nx2UL1d7',
            issuer: 'https://autht.securebanklogin.com/oauth2/default',
            environment: 'wwt',
        },
    ],
]);

/**
 * Returns OIDC client configuration by host origin
 *
 * @param {string} origin The window origin
 * @param {string} href The window href
 * @returns {OriginConfigurationObject} The configuration
 */
export default function originConfig(origin, href) {
    for (let [key, { environment, ...value }] of originHashMap.entries()) {
        if (key.includes(origin)) {
            return Object.assign(
                {
                    postLogoutRedirectUri: postLogoutConfiguration(
                        href,
                        environment,
                    ),
                },
                value,
            );
        }
    }

    return {};
}
