/* istanbul ignore file */

// TODO: Remove this before deploying to production
const host = window.location.host;

const mockConditions = [
    host.startsWith('localhost') && process.env.NODE_ENV !== 'test',
    host.startsWith('wwq'),
];

export default async function enableMock() {
    if (mockConditions.some((condition) => condition)) {
        const { worker } = await import('../../mocks/browser');
        console.info('Starting Mock Service Worker');

        /**
         * Note: if you are running this locally, this will attempt to find the service worker
         * registered under the relative path of the server.
         */
        return worker.start({
            onUnhandledRequest: 'bypass',
        });
    }

    return;
}
