import logErrors from '../logErrors';
import start from '../start';
import construct from '../construct';
import { oktaAuth } from '../authClient';
import enableMock from './enableMock';

export default async function root() {
    /* istanbul ignore next */
    await enableMock();

    // Constructs layout
    const { applications } = construct(document.querySelector('#site-layout'));

    // Logs out the errors.
    logErrors();

    // start auth
    await oktaAuth.start();

    // Starts the application.
    await start({ applications });
}
